:root {
  --container-padding-desktop: 16px 240px 64px 240px;
  --container-padding-tablet: 16px 120px 64px 120px;
  --container-padding-mobile: 16px 20px 32px 20px;
  --container-gap: 64px;
  --container-bg: #33353E;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

code {
  font-family: 'Source Code Pro', 'Manrope', monospace;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


::-webkit-scrollbar {
  display: none;
}